import React from "react";
import Logo from "../Images/Frame.svg";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation } from "react-router-dom";

function Nav({ setLang, lang, setModal, content }) {
  const link = useLocation();

  return (
    <div>
      <div className="NavBar">
        <div className="Logo">
          <img src={Logo} alt="" />
        </div>
        {link.pathname === "/" ? (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              fontSize: "12px",
            }}
            onClick={() => setModal(true)}
          >
            <InfoIcon />
            {content.infor2}
          </p>
        ) : (
          ""
        )}
        <select
          value={localStorage.getItem("lng") || lang}
          onChange={(e) => setLang(e.target.value)}
        >
          <option value={"ru"}>Ru</option>
          <option value={"uz"}>Uz</option>
        </select>
      </div>
    </div>
  );
}

export default Nav;
