import React, { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Nav from "../Components/Nav";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { createSvgIcon } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import html2canvas from "html2canvas";
import Flag from "../Images/free-icon-uzbekistan-5975795.png";
import "react-lazy-load-image-component/src/effects/blur.css";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReplyIcon from "@mui/icons-material/Reply";
import Draggable from "react-draggable";

function Nextp({ setLang, lang, content }) {
  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      np
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );

  const [text, setText] = useState("");
  const [text1, setText1] = useState("");
  const [textt2, setTextt2] = useState("");
  const [text2, setText2] = useState("");
  const [size, setSize] = useState("12px");

  function Text(params) {
    setText(params.target.value);
    localStorage.setItem("front_text1", params.target.value);
  }
  function Text1(params) {
    setText1(params.target.value);
    localStorage.setItem("back_text", params.target.value);
  }

  function Textt2(params) {
    setTextt2(params.target.value);
    localStorage.setItem("front_text2", params.target.value);
  }

  function Text2(params) {
    setText2(params.target.value);
    localStorage.setItem("front_text3", params.target.value);
  }
  const navigate = useNavigate();

  const div1Ref = useRef(null);
  const div2Ref = useRef(null);

  const Dalee = async () => {
    // const divToConvert = document.getElementById("myDiv");
    // const divToConvert2 = document.getElementById("myDiv2");
    // divToConvert.style.transform = "rotate(0.5turn)";
    // divToConvert2.style.transform = "rotate(0.5turn)";

    // html2canvas(divToConvert, divToConvert2).then((canvas) => {a
    //   const imageData = canvas.toDataURL("image/png");
    //   const imageData2 = canvas.toDataURL("image/png");
    //   // localStorage.setItem("divImage", imageData);
    //   // localStorage.setItem("divImagee2", imageData2);
    //   navigate("/view-p");
    // });
    const captureAndSave = async (ref, localStorageKey) => {
      const element = ref.current;
      try {
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL("image/png");
        localStorage.setItem(localStorageKey, imgData);
      } catch (error) {
        console.error("Error capturing element:", error);
      }
      localStorage.setItem("side", side);
    };

    await captureAndSave(div1Ref, "div1Image");
    await captureAndSave(div2Ref, "div2Image");
    navigate("/view-p");
  };

  const [font, setFont] = useState("'Inter', sans-serif");

  const [side, setSide] = useState("front");
  const [quest, setQuest] = useState(false);

  return (
    <div>
      {side === "front" ? (
        <>
          <div className="toBack" onClick={() => setSide("back")}>
            <ReplyIcon className="orqa" />
            {content.change_back}
          </div>
        </>
      ) : (
        <>
          <div className="toFront" onClick={() => setSide("front")}>
            <ReplyIcon className="oldi" />
            {content.change_front}
          </div>
        </>
      )}
      <Nav content={content} setLang={setLang} lang={lang} />
      <HelpOutlineIcon
        className="question_mark"
        onClick={() => setQuest(true)}
      />
      <div
        className={quest ? "modalka active" : "modalka"}
        onClick={() => setQuest(false)}
      >
        <div className="modal_card">
          <p>{content.info}</p>
        </div>
      </div>
      <div className="img_cost">
        <div
          className="Image"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="backImg">
            <div className="card">
              <div
                id="myDiv"
                ref={div1Ref}
                style={{
                  backgroundImage: `url(${localStorage
                    .getItem("img")
                    .replace(" ", "%20")})`,
                  width: "calc(100vw-15px)",
                  height: "100%",
                  backgroundSize: "100% auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backfaceVisibility: "hidden",
                  transform:
                    side === "back" ? "rotateY(180deg)" : "rotateY(360deg)",
                }}
                className="backgr_img"
              >
                <Draggable
                  bounds="parent"
                  position={null}
                  defaultPosition={{ x: 0, y: 350 }}
                  scale={1}
                >
                  <div
                    className="box"
                    style={
                      text.length > 0
                        ? {
                            display: "flex",
                            width: "auto",
                            color: "#fff",
                            position: "absolute",
                            fontFamily: font,
                            fontSize: size,
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                          }
                        : {
                            display: "none",
                          }
                    }
                  >
                    <span
                      className="daraag"
                      id="darag1"
                      style={{ fontFamily: font }}
                    >
                      <span id="daragSpan1"></span>
                      {text}
                      <span id="daragSpan2"></span>
                    </span>
                  </div>
                </Draggable>
                <Draggable
                  bounds="parent"
                  position={null}
                  defaultPosition={{ x: 0, y: 300 }}
                  scale={1}
                >
                  <div
                    className="box"
                    style={
                      textt2.length > 0
                        ? {
                            display: "inline-block",
                            width: "auto",
                            color: "#fff",
                            fontFamily: font,
                            fontSize: size,
                          }
                        : {
                            display: "none",
                          }
                    }
                  >
                    <span
                      className="daraag"
                      id="darag2"
                      style={{ fontFamily: font }}
                    >
                      <span id="daragSpan3"></span>
                      {textt2}
                      <span id="daragSpan4"></span>
                    </span>
                  </div>
                </Draggable>
                <p
                  className="card_text nomer"
                  id="card_text"
                  style={
                    text2.length > 0
                      ? {
                          width: "163px",
                          bottom: `60px`,
                          left: "50%",
                          transform: "translateX(-50%)",
                          textAlign: "center",
                          zIndex: 1,
                        }
                      : { display: "none" }
                  }
                >
                  <span className="vil_raq">{text2.slice(0, 2)}</span>{" "}
                  <span>
                    {text2.toUpperCase().slice(2, 3)} {text2.slice(3, 6)}{" "}
                    {text2.toUpperCase().slice(6, 8)}
                  </span>
                  <span className="flag_uz">
                    <img src={Flag} alt="" />
                    <span>UZ</span>
                  </span>{" "}
                </p>
              </div>
              <div
                id="myDiv2"
                ref={div2Ref}
                style={{
                  backgroundImage: `url(${localStorage
                    .getItem("img2")
                    .replace(" ", "%20")})`,
                  width: "calc(100vw-15px)",
                  height: "100%",
                  backgroundSize: "100% auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backfaceVisibility: "hidden",
                  transform:
                    side === "back" ? "rotateY(360deg)" : "rotateY(180deg)",
                }}
                className="backgr_img"
              >
                <Draggable
                  bounds="parent"
                  position={null}
                  defaultPosition={{ x: 0, y: 350 }}
                  scale={1}
                >
                  <div
                    className="box"
                    style={
                      text1.length > 0
                        ? {
                            display: "inline-block",
                            width: "auto",
                            color: "#fff",
                            fontFamily: font,
                            fontSize: size,
                          }
                        : {
                            display: "none",
                          }
                    }
                  >
                    <span
                      className="daraag"
                      id="darag3"
                      style={{ fontFamily: font }}
                    >
                      <span id="daragSpan5"></span>
                      {text1}
                      <span id="daragSpan6"></span>
                    </span>
                  </div>
                </Draggable>
              </div>
            </div>
          </div>
        </div>
        <div className="cost">
          <p>{content.all_cost}</p>
          <p>
            {localStorage.getItem("cost")}
            {content.sum}
          </p>
        </div>
      </div>
      {side === "front" ? (
        <div className="Accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<PlusIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>{content.add_text}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="add_text">
                <form action="">
                  <input
                    type="text"
                    className="drag_matn_input"
                    onChange={(e) => Text(e)}
                    placeholder={content.placeholder}
                    value={text}
                  />
                  <div>
                    <select
                      name=""
                      id=""
                      onChange={(e) => setFont(e.target.value)}
                    >
                      <option value="">{content.font}</option>
                      <option
                        style={{ fontFamily: "'SF Pro Display', sans-serif" }}
                        value="'SF Pro Display', sans-serif"
                      >
                        SF Pro Display
                      </option>
                      <option
                        style={{ fontFamily: "'Times New Roman', sans-seriff" }}
                        value="'Times New Roman', sans-seriff"
                      >
                        Times New Roman
                      </option>
                      <option
                        style={{ fontFamily: "'Impact', sans-serif" }}
                        value="'Impact', sans-serif"
                      >
                        Impact
                      </option>
                      <option
                        style={{ fontFamily: "'ArtScript', sans-serif" }}
                        value="'ArtScript', sans-serif"
                      >
                        ArtScript Regular
                      </option>
                      <option
                        style={{ fontFamily: "'Nova Square', sans-serif" }}
                        value="'Nova Square', sans-serif"
                      >
                        Nova Square
                      </option>
                    </select>
                    <select
                      name=""
                      id=""
                      onChange={(e) => setSize(e.target.value)}
                    >
                      <option value="12px">{content.size}</option>
                      <option value="12px">12</option>
                      <option value="16px">16</option>
                      <option value="18px">28</option>
                      <option value="20px">20</option>
                      <option value="22px">22</option>
                      <option value="24px">24</option>
                      <option value="26px">26</option>
                      <option value="28px">28</option>
                      <option value="30px">30</option>
                    </select>
                  </div>
                </form>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<PlusIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>{content.add_text}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="add_text">
                <form action="">
                  <input
                    type="text"
                    className="drag_matn_input"
                    onChange={(e) => Textt2(e)}
                    placeholder={content.placeholder}
                    value={textt2}
                  />
                </form>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<PlusIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography>{content.car_num}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="add_text">
                <form action="">
                  <input
                    type="text"
                    value={text2}
                    maxLength={8}
                    onChange={(e) => Text2(e)}
                    placeholder={content.placeholder}
                  />
                </form>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : localStorage.getItem("backIm") === "true" ? (
        <div className="Accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<PlusIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>{content.add_text}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="add_text">
                <form action="">
                  <input
                    type="text"
                    className="drag_matn_input"
                    onChange={(e) => Text1(e)}
                    placeholder={content.placeholder}
                    value={text1}
                  />
                </form>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        ""
      )}
      <div className="dalee2">
        <NavLink to="/select-d">
          <button>
            <KeyboardBackspaceIcon />
          </button>
        </NavLink>
        <button
          onClick={() => {
            document.getElementById("darag1").style.border = "none";
            document.getElementById("darag2").style.border = "none";
            document.getElementById("darag3").style.border = "none";
            document.getElementById("daragSpan1").style.display = "none";
            document.getElementById("daragSpan2").style.display = "none";
            document.getElementById("daragSpan3").style.display = "none";
            document.getElementById("daragSpan4").style.display = "none";
            document.getElementById("daragSpan5").style.display = "none";
            document.getElementById("daragSpan6").style.display = "none";
            Dalee();
          }}
        >
          {content.next}
        </button>
      </div>
    </div>
  );
}

export default Nextp;
