import React, { useState } from "react";
import Img from "../Images/155151142424-01 1.svg";
import Nav from "../Components/Nav";
import { NavLink } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import Boshi1 from "../Images/boshi/1.png";
import Boshi2 from "../Images/boshi/Kardholder02 копия11150114151450101041-06.png";
import Boshi3 from "../Images/boshi/2.png";
import Boshi4 from "../Images/boshi/3.png";
import Boshi5 from "../Images/boshi/4.png";
import Boshi6 from "../Images/boshi/5.png";
import Boshi7 from "../Images/boshi/6.png";
import Boshi8 from "../Images/boshi/7.png";
import Boshi9 from "../Images/boshi/8.png";
import Boshi10 from "../Images/boshi/9.png";
import Boshi11 from "../Images/boshi/10.png";
import Boshi12 from "../Images/boshi/11.png";
import Boshi13 from "../Images/boshi/12.png";
import Boshi14 from "../Images/boshi/13.png";
import Boshi15 from "../Images/boshi/14.png";

import Gerb1 from "../Images/Герб/G1.png";
import Gerb2 from "../Images/Герб/G2.png";
import Gerb3 from "../Images/Герб/G3.png";
import Gerb4 from "../Images/Герб/G4.png";
import Gerb5 from "../Images/Герб/G5.png";
import Gerb6 from "../Images/Герб/G6.png";
import Gerb7 from "../Images/Герб/G7.png";
import Gerb8 from "../Images/Герб/G8.png";
import Gerb9 from "../Images/Герб/G9.png";
import Gerb10 from "../Images/Герб/G10.png";
import Gerb11 from "../Images/Герб/G11.png";
import Gerb12 from "../Images/Герб/G12.png";

import Crypto1 from "../Images/криптовалюта/01-15.png";
import Crypto2 from "../Images/криптовалюта/01-16.png";
import Crypto3 from "../Images/криптовалюта/01-21.png";
import Crypto4 from "../Images/криптовалюта/01-43.png";
import Crypto5 from "../Images/криптовалюта/C1.png";
import Crypto6 from "../Images/криптовалюта/C2.png";
import Crypto7 from "../Images/криптовалюта/C3.png";
import Crypto8 from "../Images/криптовалюта/C4.png";
import Crypto9 from "../Images/криптовалюта/C5.png";
import Crypto10 from "../Images/криптовалюта/C6.png";
import Crypto11 from "../Images/криптовалюта/C7.png";
import Crypto12 from "../Images/криптовалюта/C8.png";
import Crypto13 from "../Images/криптовалюта/C9.png";
import Crypto14 from "../Images/криптовалюта/C10.png";
import Crypto15 from "../Images/криптовалюта/C11.png";
import Crypto16 from "../Images/криптовалюта/1-02.png";
import Crypto17 from "../Images/криптовалюта/1-05.png";
import Crypto18 from "../Images/криптовалюта/1-25.png";

import Money1 from "../Images/деньги/01-15.png";
import Money2 from "../Images/деньги/01-17.png";
import Money3 from "../Images/деньги/01-18.png";
import Money4 from "../Images/деньги/01-19.png";
import Money5 from "../Images/деньги/01-20.png";
import Money6 from "../Images/деньги/01-22.png";
import Money7 from "../Images/деньги/01-36.png";
import Money8 from "../Images/деньги/01-42.png";
import Money9 from "../Images/деньги/M1.png";

import Brend1 from "../Images/бренды/01-23.png";
import Brend2 from "../Images/бренды/01-26.png";
import Brend3 from "../Images/бренды/01-30.png";
import Brend4 from "../Images/бренды/01-31.png";
import Brend5 from "../Images/бренды/01-32.png";
import Brend6 from "../Images/бренды/01-32.png";
import Brend7 from "../Images/бренды/01-33.png";
import Brend8 from "../Images/бренды/01-34.png";
import Brend9 from "../Images/бренды/01-35.png";
import Brend10 from "../Images/бренды/01-38.png";
import Brend11 from "../Images/бренды/01-41.png";
import Brend12 from "../Images/бренды/01-48.png";
import Brend13 from "../Images/бренды/01-49.png";
import Brend14 from "../Images/бренды/01-50.png";
import Brend15 from "../Images/бренды/01-51.png";
import Brend16 from "../Images/бренды/01-52.png";
import Brend17 from "../Images/бренды/01-54.png";
import Brend18 from "../Images/бренды/01-55.png";
import Brend19 from "../Images/бренды/01-56.png";
import Brend20 from "../Images/бренды/01-57.png";
import Brend21 from "../Images/бренды/b1.png";
import Brend22 from "../Images/бренды/b2.png";
import Brend23 from "../Images/бренды/b3.png";
import Brend24 from "../Images/бренды/b4.png";
import Brend25 from "../Images/бренды/b5.png";
import Brend26 from "../Images/бренды/b6.png";
import Brend27 from "../Images/бренды/b7.png";
import Brend28 from "../Images/бренды/b8.png";
import Brend29 from "../Images/бренды/b9.png";
import Brend30 from "../Images/бренды/b10.png";
import Brend31 from "../Images/бренды/b11.png";
import Brend32 from "../Images/бренды/1-14.png";
import Brend33 from "../Images/бренды/1-16.png";
import Brend34 from "../Images/бренды/1-29.png";
import Brend35 from "../Images/бренды/1-44.png";
import Brend36 from "../Images/бренды/1-45.png";
import Brend37 from "../Images/бренды/1-46.png";
import Brend38 from "../Images/бренды/1-50.png";
import Brend39 from "../Images/бренды/1-51.png";
import Brend40 from "../Images/бренды/1-53.png";
import Brend41 from "../Images/бренды/1-72.png";

import Kosmos from "../Images/космос/k1.png";
import Kosmos1 from "../Images/космос/k2.png";
import Kosmos2 from "../Images/космос/k3.png";
import Kosmos3 from "../Images/космос/k4.png";
import Kosmos4 from "../Images/космос/k5.png";
import Kosmos5 from "../Images/космос/k6.png";
import Kosmos6 from "../Images/космос/121212121-34.png";

import Love from "../Images/любовь/l1.png";
import Love1 from "../Images/любовь/l2.png";
import Love2 from "../Images/любовь/l3.png";
import Love3 from "../Images/любовь/l4.png";

import Nature1 from "../Images/природа/01-08.png";
import Nature2 from "../Images/природа/01-09.png";
import Nature3 from "../Images/природа/n1.png";
import Nature4 from "../Images/природа/n2.png";
import Nature5 from "../Images/природа/n3.png";
import Nature6 from "../Images/природа/n4.png";
import Nature7 from "../Images/природа/1-19.png";
import Nature8 from "../Images/природа/1-20.png";
import Nature9 from "../Images/природа/1-21.png";

import Animal1 from "../Images/Животные/01-02.png";
import Animal2 from "../Images/Животные/01-03.png";
import Animal3 from "../Images/Животные/01-04.png";
import Animal4 from "../Images/Животные/01-05.png";
import Animal5 from "../Images/Животные/01-06.png";
import Animal6 from "../Images/Животные/01-07.png";
import Animal7 from "../Images/Животные/01-10.png";
import Animal8 from "../Images/Животные/01-14.png";
import Animal9 from "../Images/Животные/01-24.png";
import Animal10 from "../Images/Животные/01-25.png";
import Animal11 from "../Images/Животные/a1.png";
import Animal12 from "../Images/Животные/a2.png";
import Animal13 from "../Images/Животные/a3.png";
import Animal14 from "../Images/Животные/a4.png";
import Animal15 from "../Images/Животные/a5.png";
import Animal16 from "../Images/Животные/a6.png";
import Animal17 from "../Images/Животные/a7.png";

import Exlusive1 from "../Images/эксклюзив/01-11.png";
import Exlusive2 from "../Images/эксклюзив/01-12.png";
import Exlusive3 from "../Images/эксклюзив/01-37.png";
import Exlusive4 from "../Images/эксклюзив/01-39.png";
import Exlusive5 from "../Images/эксклюзив/01-40.png";
import Exlusive6 from "../Images/эксклюзив/01-44.png";
import Exlusive7 from "../Images/эксклюзив/e1.png";
import Exlusive8 from "../Images/эксклюзив/e2.png";
import Exlusive9 from "../Images/эксклюзив/e3.png";
import Exlusive10 from "../Images/эксклюзив/e4.png";
import Exlusive11 from "../Images/эксклюзив/e5.png";
import Exlusive12 from "../Images/эксклюзив/e6.png";
import Exlusive13 from "../Images/эксклюзив/e7.png";
import Exlusive14 from "../Images/эксклюзив/e8.png";
import Exlusive15 from "../Images/эксклюзив/e9.png";
import Exlusive16 from "../Images/эксклюзив/e10.png";
import Exlusive17 from "../Images/эксклюзив/e11.png";
import Exlusive18 from "../Images/эксклюзив/e12.png";
import Exlusive19 from "../Images/эксклюзив/e13.png";
import Exlusive20 from "../Images/эксклюзив/e14.png";

import Sport1 from "../Images/спорт/s1.png";
import Sport2 from "../Images/спорт/s2.png";
import Sport3 from "../Images/спорт/s3.png";
import Sport4 from "../Images/спорт/s4.png";
import Sport5 from "../Images/спорт/s5.png";
import Sport6 from "../Images/спорт/s6.png";
import Sport7 from "../Images/спорт/s7.png";
import Sport8 from "../Images/спорт/s8.png";
import Sport9 from "../Images/спорт/s9.png";
import Sport10 from "../Images/спорт/s10.png";
import Sport11 from "../Images/спорт/s11.png";
import Sport12 from "../Images/спорт/s12.png";
import Sport13 from "../Images/спорт/s13.png";
import Sport14 from "../Images/спорт/s14.png";
import Sport15 from "../Images/спорт/s15.png";
import Sport16 from "../Images/спорт/s16.png";
import Sport17 from "../Images/спорт/s17.png";
import Sport18 from "../Images/спорт/s18.png";

import Car1 from "../Images/машины/c1.png";
import Car2 from "../Images/машины/c2.png";
import Car3 from "../Images/машины/c3.png";
import Car4 from "../Images/машины/c4.png";
import Car5 from "../Images/машины/c5.png";
import Car6 from "../Images/машины/c6.png";
import Car7 from "../Images/машины/c7.png";
import Car8 from "../Images/машины/c8.png";
import Car9 from "../Images/машины/c9.png";
import Car10 from "../Images/машины/c10.png";
import Car11 from "../Images/машины/c11.png";
import Car12 from "../Images/машины/c12.png";
import Car13 from "../Images/машины/c13.png";
import Car14 from "../Images/машины/c14.png";
import Car15 from "../Images/машины/c15.png";
import Car16 from "../Images/машины/c16.png";
import Car17 from "../Images/машины/c17.png";
import Car18 from "../Images/машины/c18.png";
import Car19 from "../Images/машины/c19.png";
import Car20 from "../Images/машины/c20.png";
import Car21 from "../Images/машины/c21.png";
import Car22 from "../Images/машины/c22.png";
import Car23 from "../Images/машины/c23.png";
import Car24 from "../Images/машины/c24.png";
import Car25 from "../Images/машины/c25.png";
import Car26 from "../Images/машины/c26.png";
import Car27 from "../Images/машины/c27.png";
import Car28 from "../Images/машины/c28.png";
import Car29 from "../Images/машины/c29.png";
import Car30 from "../Images/машины/c30.png";
import Car31 from "../Images/машины/c31.png";
import Car32 from "../Images/машины/c32.png";
import Car33 from "../Images/машины/c33.png";
import Car34 from "../Images/машины/c34.png";
import Car35 from "../Images/машины/c35.png";
import Car36 from "../Images/машины/c36.png";
import Car37 from "../Images/машины/c37.png";
import Car38 from "../Images/машины/c38.png";
import Car39 from "../Images/машины/c39.png";
import Car40 from "../Images/машины/c40.png";

import Other1 from "../Images/Прочее/01-01.png";
import Other2 from "../Images/Прочее/01-13.png";
import Other3 from "../Images/Прочее/01-27.png";
import Other4 from "../Images/Прочее/01-28.png";
import Other5 from "../Images/Прочее/01-29.png";
import Other6 from "../Images/Прочее/01-45.png";
import Other7 from "../Images/Прочее/01-46.png";
import Other8 from "../Images/Прочее/01-47.png";
import Other9 from "../Images/Прочее/01-53.png";
import Other10 from "../Images/Прочее/01-58.png";
import Other11 from "../Images/Прочее/a1.png";
import Other12 from "../Images/Прочее/a2.png";
import Other13 from "../Images/Прочее/a3.png";
import Other14 from "../Images/Прочее/a4.png";
import Other15 from "../Images/Прочее/a5.png";
import Other16 from "../Images/Прочее/a6.png";
import Other17 from "../Images/Прочее/a7.png";
import Other18 from "../Images/Прочее/a8.png";
import Other19 from "../Images/Прочее/a9.png";
import Other20 from "../Images/Прочее/a10.png";
import Other21 from "../Images/Прочее/a11.png";
import Other22 from "../Images/Прочее/a12.png";
import Other23 from "../Images/Прочее/a13.png";
import Other24 from "../Images/Прочее/a14.png";
import Other25 from "../Images/Прочее/a15.png";
import Other26 from "../Images/Прочее/a16.png";
import Other27 from "../Images/Прочее/a17.png";
import Other28 from "../Images/Прочее/a18.png";
import Other29 from "../Images/Прочее/a19.png";
import Other30 from "../Images/Прочее/a20.png";
import Other31 from "../Images/Прочее/a21.png";
import Other32 from "../Images/Прочее/a22.png";
import Other33 from "../Images/Прочее/a23.png";
import Other34 from "../Images/Прочее/a24.png";
import Other35 from "../Images/Прочее/a25.png";
import Other36 from "../Images/Прочее/a26.png";
import Other37 from "../Images/Прочее/a27.png";
import Other38 from "../Images/Прочее/a28.png";
import Other39 from "../Images/Прочее/a29.png";
import Other40 from "../Images/Прочее/a30.png";
import Other41 from "../Images/Прочее/a31.png";
import Other42 from "../Images/Прочее/a32.png";
import Other43 from "../Images/Прочее/a33.png";
import Other44 from "../Images/Прочее/a34.png";
import Other45 from "../Images/Прочее/a35.png";
import Other46 from "../Images/Прочее/a36.png";
import Other47 from "../Images/Прочее/a37.png";
import Other48 from "../Images/Прочее/a38.png";
import Other49 from "../Images/Прочее/a39.png";
import Other50 from "../Images/Прочее/a40.png";
import Other51 from "../Images/Прочее/a41.png";
import Other52 from "../Images/Прочее/1-07.png";
import Other53 from "../Images/Прочее/1-10.png";
import Other54 from "../Images/Прочее/1-12.png";
import Other55 from "../Images/Прочее/1-15.png";
import Other56 from "../Images/Прочее/1-37.png";
import Other57 from "../Images/Прочее/1-39.png";
import Other58 from "../Images/Прочее/1-49.png";
import Other59 from "../Images/Прочее/1-52.png";
import Other60 from "../Images/Прочее/1-54.png";
import Other61 from "../Images/Прочее/1-56.png";
import Other62 from "../Images/Прочее/1-58.png";
import Other63 from "../Images/Прочее/1-61.png";
import Other64 from "../Images/Прочее/1-64.png";
import Other65 from "../Images/Прочее/1-66.png";
import Other66 from "../Images/Прочее/1-70.png";
import Other67 from "../Images/Прочее/1-71.png";
import Other68 from "../Images/Прочее/1-73.png";

import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import ReplyIcon from "@mui/icons-material/Reply";

const SelectD = ({ content, setLang, lang }) => {
  const [imagee, setImagee] = useState(Img);
  const [imagee2, setImagee2] = useState(Img);

  function Select1(params) {
    setImagee(params);
  }
  function Select2(params) {
    setImagee2(params);
  }

  const img = [
    {
      category: "20",
      image: Boshi1,
    },
    {
      category: "20",
      image: Boshi2,
    },
    {
      category: "20",
      image: Other52,
    },
    {
      category: "20",
      image: Boshi3,
    },
    {
      category: "20",
      image: Boshi4,
    },
    {
      category: "20",
      image: Boshi5,
    },
    {
      category: "20",
      image: Boshi6,
    },
    {
      category: "20",
      image: Boshi7,
    },
    {
      category: "20",
      image: Boshi8,
    },
    {
      category: "20",
      image: Boshi9,
    },
    {
      category: "20",
      image: Boshi10,
    },
    {
      category: "20",
      image: Boshi11,
    },
    {
      category: "20",
      image: Boshi12,
    },
    {
      category: "20",
      image: Boshi13,
    },
    {
      category: "20",
      image: Boshi14,
    },
    {
      category: "20",
      image: Boshi15,
    },
    {
      category: "1",
      image: Gerb1,
    },
    {
      category: "1",
      image: Gerb2,
    },
    {
      category: "1",
      image: Gerb3,
    },
    {
      category: "1",
      image: Gerb4,
    },
    {
      category: "1",
      image: Gerb5,
    },
    {
      category: "1",
      image: Gerb6,
    },
    {
      category: "1",
      image: Gerb7,
    },
    {
      category: "1",
      image: Gerb8,
    },
    {
      category: "1",
      image: Gerb9,
    },
    {
      category: "1",
      image: Gerb10,
    },
    {
      category: "1",
      image: Gerb11,
    },
    {
      category: "1",
      image: Gerb12,
    },
    {
      category: "2",
      image: Crypto1,
    },
    {
      category: "2",
      image: Crypto2,
    },
    {
      category: "2",
      image: Crypto3,
    },
    {
      category: "2",
      image: Crypto4,
    },
    {
      category: "2",
      image: Crypto5,
    },
    {
      category: "2",
      image: Crypto6,
    },
    {
      category: "2",
      image: Crypto7,
    },
    {
      category: "2",
      image: Crypto8,
    },
    {
      category: "2",
      image: Crypto9,
    },
    {
      category: "2",
      image: Crypto10,
    },
    {
      category: "2",
      image: Crypto11,
    },
    {
      category: "2",
      image: Crypto12,
    },
    {
      category: "2",
      image: Crypto13,
    },
    {
      category: "2",
      image: Crypto14,
    },
    {
      category: "2",
      image: Crypto15,
    },
    {
      category: "2",
      image: Crypto16,
    },
    {
      category: "2",
      image: Crypto17,
    },
    {
      category: "2",
      image: Crypto18,
    },
    {
      category: "3",
      image: Money1,
    },
    {
      category: "3",
      image: Money2,
    },
    {
      category: "3",
      image: Money3,
    },
    {
      category: "3",
      image: Money4,
    },
    {
      category: "3",
      image: Money5,
    },
    {
      category: "3",
      image: Money6,
    },
    {
      category: "3",
      image: Money7,
    },
    {
      category: "3",
      image: Money8,
    },
    {
      category: "3",
      image: Money9,
    },
    {
      category: "4",
      image: Brend1,
    },
    {
      category: "4",
      image: Brend2,
    },
    {
      category: "4",
      image: Brend3,
    },
    {
      category: "4",
      image: Brend4,
    },
    {
      category: "4",
      image: Brend5,
    },
    {
      category: "4",
      image: Brend6,
    },
    {
      category: "4",
      image: Brend7,
    },
    {
      category: "4",
      image: Brend8,
    },
    {
      category: "4",
      image: Brend9,
    },
    {
      category: "4",
      image: Brend10,
    },
    {
      category: "4",
      image: Brend11,
    },
    {
      category: "4",
      image: Brend12,
    },
    {
      category: "4",
      image: Brend13,
    },
    {
      category: "4",
      image: Brend14,
    },
    {
      category: "4",
      image: Brend15,
    },
    {
      category: "4",
      image: Brend16,
    },
    {
      category: "4",
      image: Brend17,
    },
    {
      category: "4",
      image: Brend18,
    },
    {
      category: "4",
      image: Brend19,
    },
    {
      category: "4",
      image: Brend20,
    },
    {
      category: "4",
      image: Brend21,
    },
    {
      category: "4",
      image: Brend22,
    },
    {
      category: "4",
      image: Brend23,
    },
    {
      category: "4",
      image: Brend24,
    },
    {
      category: "4",
      image: Brend25,
    },
    {
      category: "4",
      image: Brend26,
    },
    {
      category: "4",
      image: Brend27,
    },
    {
      category: "4",
      image: Brend28,
    },
    {
      category: "4",
      image: Brend29,
    },
    {
      category: "4",
      image: Brend30,
    },
    {
      category: "4",
      image: Brend31,
    },
    {
      category: "4",
      image: Brend32,
    },
    {
      category: "4",
      image: Brend33,
    },
    {
      category: "4",
      image: Brend34,
    },
    {
      category: "4",
      image: Brend35,
    },
    {
      category: "4",
      image: Brend36,
    },
    {
      category: "4",
      image: Brend37,
    },
    {
      category: "4",
      image: Brend38,
    },
    {
      category: "4",
      image: Brend39,
    },
    {
      category: "4",
      image: Brend40,
    },
    {
      category: "4",
      image: Brend41,
    },

    {
      category: "5",
      image: Kosmos,
    },
    {
      category: "5",
      image: Kosmos1,
    },
    {
      category: "5",
      image: Kosmos2,
    },
    {
      category: "5",
      image: Kosmos3,
    },
    {
      category: "5",
      image: Kosmos4,
    },
    {
      category: "5",
      image: Kosmos5,
    },
    {
      category: "5",
      image: Kosmos6,
    },
    {
      category: "6",
      image: Love,
    },
    {
      category: "6",
      image: Love1,
    },
    {
      category: "6",
      image: Love2,
    },
    {
      category: "6",
      image: Love3,
    },
    {
      category: "7",
      image: Nature1,
    },
    {
      category: "7",
      image: Nature2,
    },
    {
      category: "7",
      image: Nature3,
    },
    {
      category: "7",
      image: Nature4,
    },
    {
      category: "7",
      image: Nature4,
    },
    {
      category: "7",
      image: Nature5,
    },
    {
      category: "7",
      image: Nature6,
    },
    {
      category: "7",
      image: Nature7,
    },
    {
      category: "7",
      image: Nature8,
    },
    {
      category: "7",
      image: Nature9,
    },
    {
      category: "8",
      image: Animal1,
    },
    {
      category: "8",
      image: Animal2,
    },
    {
      category: "8",
      image: Animal3,
    },
    {
      category: "8",
      image: Animal4,
    },
    {
      category: "8",
      image: Animal5,
    },
    {
      category: "8",
      image: Animal6,
    },
    {
      category: "8",
      image: Animal7,
    },
    {
      category: "8",
      image: Animal8,
    },
    {
      category: "8",
      image: Animal9,
    },
    {
      category: "8",
      image: Animal10,
    },
    {
      category: "8",
      image: Animal11,
    },
    {
      category: "8",
      image: Animal12,
    },
    {
      category: "8",
      image: Animal13,
    },
    {
      category: "8",
      image: Animal14,
    },
    {
      category: "8",
      image: Animal15,
    },
    {
      category: "8",
      image: Animal16,
    },
    {
      category: "8",
      image: Animal17,
    },
    {
      category: "9",
      image: Exlusive1,
    },
    {
      category: "9",
      image: Exlusive2,
    },
    {
      category: "9",
      image: Exlusive3,
    },
    {
      category: "9",
      image: Exlusive4,
    },
    {
      category: "9",
      image: Exlusive5,
    },
    {
      category: "9",
      image: Exlusive6,
    },
    {
      category: "9",
      image: Exlusive7,
    },
    {
      category: "9",
      image: Exlusive8,
    },
    {
      category: "9",
      image: Exlusive9,
    },
    {
      category: "9",
      image: Exlusive10,
    },
    {
      category: "9",
      image: Exlusive11,
    },
    {
      category: "9",
      image: Exlusive12,
    },
    {
      category: "9",
      image: Exlusive13,
    },
    {
      category: "9",
      image: Exlusive14,
    },
    {
      category: "9",
      image: Exlusive15,
    },
    {
      category: "9",
      image: Exlusive16,
    },
    {
      category: "9",
      image: Exlusive17,
    },
    {
      category: "9",
      image: Exlusive18,
    },
    {
      category: "9",
      image: Exlusive19,
    },
    {
      category: "9",
      image: Exlusive20,
    },

    {
      category: "10",
      image: Sport1,
    },
    {
      category: "10",
      image: Sport2,
    },
    {
      category: "10",
      image: Sport3,
    },
    {
      category: "10",
      image: Sport4,
    },
    {
      category: "10",
      image: Sport5,
    },
    {
      category: "10",
      image: Sport6,
    },
    {
      category: "10",
      image: Sport7,
    },
    {
      category: "10",
      image: Sport8,
    },
    {
      category: "10",
      image: Sport9,
    },
    {
      category: "10",
      image: Sport10,
    },
    {
      category: "10",
      image: Sport11,
    },
    {
      category: "10",
      image: Sport12,
    },
    {
      category: "10",
      image: Sport13,
    },
    {
      category: "10",
      image: Sport14,
    },
    {
      category: "10",
      image: Sport15,
    },
    {
      category: "10",
      image: Sport16,
    },
    {
      category: "10",
      image: Sport17,
    },
    {
      category: "10",
      image: Sport18,
    },

    {
      category: "11",
      image: Car1,
    },
    {
      category: "11",
      image: Car2,
    },
    {
      category: "11",
      image: Car3,
    },
    {
      category: "11",
      image: Car4,
    },
    {
      category: "11",
      image: Car5,
    },
    {
      category: "11",
      image: Car6,
    },
    {
      category: "11",
      image: Car7,
    },
    {
      category: "11",
      image: Car8,
    },
    {
      category: "11",
      image: Car9,
    },
    {
      category: "11",
      image: Car10,
    },
    {
      category: "11",
      image: Car11,
    },
    {
      category: "11",
      image: Car12,
    },
    {
      category: "11",
      image: Car13,
    },
    {
      category: "11",
      image: Car14,
    },
    {
      category: "11",
      image: Car15,
    },
    {
      category: "11",
      image: Car16,
    },
    {
      category: "11",
      image: Car17,
    },
    {
      category: "11",
      image: Car18,
    },
    {
      category: "11",
      image: Car19,
    },
    {
      category: "11",
      image: Car20,
    },
    {
      category: "11",
      image: Car21,
    },
    {
      category: "11",
      image: Car22,
    },
    {
      category: "11",
      image: Car23,
    },
    {
      category: "11",
      image: Car24,
    },
    {
      category: "11",
      image: Car25,
    },
    {
      category: "11",
      image: Car26,
    },
    {
      category: "11",
      image: Car27,
    },
    {
      category: "11",
      image: Car28,
    },
    {
      category: "11",
      image: Car29,
    },
    {
      category: "11",
      image: Car30,
    },
    {
      category: "11",
      image: Car31,
    },
    {
      category: "11",
      image: Car32,
    },
    {
      category: "11",
      image: Car33,
    },
    {
      category: "11",
      image: Car34,
    },
    {
      category: "11",
      image: Car35,
    },
    {
      category: "11",
      image: Car36,
    },
    {
      category: "11",
      image: Car37,
    },
    {
      category: "11",
      image: Car38,
    },
    {
      category: "11",
      image: Car39,
    },
    {
      category: "11",
      image: Car40,
    },

    {
      category: "12",
      image: Other1,
    },
    {
      category: "12",
      image: Other2,
    },
    {
      category: "12",
      image: Other3,
    },
    {
      category: "12",
      image: Other4,
    },
    {
      category: "12",
      image: Other5,
    },
    {
      category: "12",
      image: Other6,
    },
    {
      category: "12",
      image: Other7,
    },
    {
      category: "12",
      image: Other8,
    },
    {
      category: "12",
      image: Other9,
    },
    {
      category: "12",
      image: Other10,
    },
    {
      category: "12",
      image: Other11,
    },
    {
      category: "12",
      image: Other12,
    },
    {
      category: "12",
      image: Other13,
    },
    {
      category: "12",
      image: Other14,
    },
    {
      category: "12",
      image: Other15,
    },
    {
      category: "12",
      image: Other16,
    },
    {
      category: "12",
      image: Other17,
    },
    {
      category: "12",
      image: Other18,
    },
    {
      category: "12",
      image: Other19,
    },
    {
      category: "12",
      image: Other20,
    },
    {
      category: "12",
      image: Other21,
    },
    {
      category: "12",
      image: Other22,
    },
    {
      category: "12",
      image: Other23,
    },
    {
      category: "12",
      image: Other24,
    },
    {
      category: "12",
      image: Other25,
    },
    {
      category: "12",
      image: Other26,
    },
    {
      category: "12",
      image: Other27,
    },
    {
      category: "12",
      image: Other28,
    },
    {
      category: "12",
      image: Other29,
    },
    {
      category: "12",
      image: Other30,
    },
    {
      category: "12",
      image: Other31,
    },
    {
      category: "12",
      image: Other32,
    },
    {
      category: "12",
      image: Other33,
    },
    {
      category: "12",
      image: Other34,
    },
    {
      category: "12",
      image: Other35,
    },
    {
      category: "12",
      image: Other36,
    },
    {
      category: "12",
      image: Other37,
    },
    {
      category: "12",
      image: Other38,
    },
    {
      category: "12",
      image: Other39,
    },
    {
      category: "12",
      image: Other40,
    },
    {
      category: "12",
      image: Other41,
    },
    {
      category: "12",
      image: Other42,
    },
    {
      category: "12",
      image: Other43,
    },
    {
      category: "12",
      image: Other44,
    },
    {
      category: "12",
      image: Other45,
    },
    {
      category: "12",
      image: Other46,
    },
    {
      category: "12",
      image: Other47,
    },
    {
      category: "12",
      image: Other48,
    },
    {
      category: "12",
      image: Other49,
    },
    {
      category: "12",
      image: Other50,
    },
    {
      category: "12",
      image: Other51,
    },

    {
      category: "12",
      image: Other53,
    },
    {
      category: "12",
      image: Other54,
    },
    {
      category: "12",
      image: Other55,
    },
    {
      category: "12",
      image: Other56,
    },
    {
      category: "12",
      image: Other57,
    },
    {
      category: "12",
      image: Other58,
    },
    {
      category: "12",
      image: Other59,
    },
    {
      category: "12",
      image: Other60,
    },
    {
      category: "12",
      image: Other61,
    },
    {
      category: "12",
      image: Other62,
    },
    {
      category: "12",
      image: Other63,
    },
    {
      category: "12",
      image: Other64,
    },
    {
      category: "12",
      image: Other65,
    },
    {
      category: "12",
      image: Other66,
    },
    {
      category: "12",
      image: Other67,
    },
    {
      category: "12",
      image: Other68,
    },
  ];

  const [selectedImg, setSImg] = useState("0");

  let i = 0;

  const [side, setSide] = useState("front");

  function ifelse(params) {
    if (imagee2 === Img) {
      localStorage.setItem("img2", Img);
    } else {
      localStorage.setItem("img2", imagee2);
    }

    if (imagee === Img) {
      localStorage.setItem("img", Img);
    } else {
      localStorage.setItem("img", imagee);
    }
  }

  localStorage.setItem("cost", "70.000");

  if (imagee2 === Img) {
    localStorage.setItem("cost", "70.000");
    localStorage.setItem("c12", "ol");
    localStorage.setItem("backIm", false);
  } else {
    localStorage.setItem("cost", "99.000");
    localStorage.setItem("c12", "or");
    localStorage.setItem("backIm", true);
  }

  return (
    <div>
      <Nav setLang={setLang} content={content} lang={lang} />
      {side === "front" ? (
        <>
          <div className="toBack" onClick={() => setSide("back")}>
            <ReplyIcon className="orqa" />
            {content.change_back}
          </div>
        </>
      ) : (
        <>
          <div className="toFront" onClick={() => setSide("front")}>
            <ReplyIcon className="oldi" />
            {content.change_front}
          </div>
          {imagee2 !== Img ? (
            <div
              className="cencelBack"
              style={{ textAlign: "center" }}
              onClick={() => setImagee2(Img)}
            >
              {content.cencel_back}
            </div>
          ) : (
            ""
          )}
        </>
      )}
      <div className="img_cost">
        <div className="Image">
          <div className="backImg select-img">
            <img
              src={imagee}
              style={
                side === "front"
                  ? { transform: "rotateY(0.5turn)" }
                  : { transform: "rotateY(0)" }
              }
              alt=""
              className="front"
            />
            <img
              src={imagee2}
              style={
                side === "back"
                  ? { transform: "rotateY(0.5turn)" }
                  : { transform: "rotateY(0)" }
              }
              alt=""
              className="back"
            />
          </div>
        </div>
        <div className="cost">
          <p>{content.all_cost}</p>
          <p>
            {localStorage.getItem("cost")}
            {content.sum}
          </p>
        </div>
      </div>
      <div className="select_design">
        <div className="df" style={{ gap: "15px" }}>
          <p>{content.select_design}</p>
          {/* <form action="">
            <label htmlFor="front">
              <input
                type="radio"
                onChange={(e) => onChangeHandle(e)}
                checked={side === "front" ? true : false}
                value="front"
                name="side"
                id="front"
              />{" "}
              Лицевая сторона
            </label>
            <label htmlFor="back">
              <input
                type="radio"
                onChange={(e) => onChangeHandle(e)}
                checked={side === "back" ? true : false}
                value="back"
                name="side"
                id="back"
              />{" "}
              Задняя сторона
            </label>
          </form> */}
        </div>
        <select
          name=""
          defaultValue={20}
          onChange={(e) => setSImg(e.target.value)}
          id=""
        >
          <option value={"0"}>{content.categ}</option>
          <option value={"1"}>{content.gerb}</option>
          <option value={"2"}>{content.crypto}</option>
          <option value={"3"}>{content.money}</option>
          <option value={"4"}>{content.brend}</option>
          <option value={"5"}>{content.kosmos}</option>
          <option value={"6"}>{content.love}</option>
          <option value={"7"}>{content.tabiat}</option>
          <option value={"8"}>{content.animals}</option>
          <option value={"9"}>{content.ex}</option>
          <option value={"10"}>{content.sport}</option>
          <option value={"11"}>{content.car}</option>
          <option value={"12"}>{content.others}</option>
        </select>
        <div className="images">
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {side === "back"
              ? selectedImg !== "0"
                ? img
                    .filter((item) => item.category === selectedImg)
                    .map((val) => (
                      <SwiperSlide
                        className="image1 cardds"
                        key={i++}
                        onClick={() => Select2(val.image)}
                      >
                        <img src={val.image} alt="" />
                      </SwiperSlide>
                    ))
                : img.map((val) => (
                    <SwiperSlide
                      className="image1 cardds"
                      key={i++}
                      onClick={() => Select2(val.image)}
                    >
                      <img src={val.image} alt="" />
                    </SwiperSlide>
                  ))
              : selectedImg !== "0"
              ? img
                  .filter((item) => item.category === selectedImg)
                  .map((val) => (
                    <SwiperSlide
                      className="image1 cardds"
                      key={i++}
                      onClick={() => Select1(val.image)}
                    >
                      <img src={val.image} alt="" />
                    </SwiperSlide>
                  ))
              : img.map((val) => (
                  <SwiperSlide
                    className="image1 cardds"
                    key={i++}
                    onClick={() => Select1(val.image)}
                  >
                    <img src={val.image} alt="" />
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
      </div>
      <div className="dalee2">
        <NavLink to="/">
          <button>
            <KeyboardBackspaceIcon />
          </button>
        </NavLink>
        <NavLink to="/next-p">
          <button onClick={() => ifelse()}>{content.next}</button>
        </NavLink>
      </div>
    </div>
  );
};

export default SelectD;
