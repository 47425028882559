import "./App.css";
import Main from "./Pages/Main";
import { Routes, Route } from "react-router-dom";
import SelectD from "./Pages/SelectD";
import Nextp from "./Pages/Next-p";
import ViewP from "./Pages/ViewP";
import Finish from "./Pages/Finish";
import { useEffect, useState } from "react";
import Translation from "./Components/Data.json";

function App() {
  const [lang, setLang] = useState("ru");
  const [content, setContent] = useState({});

  useEffect(() => {
    if (lang === "ru") {
      setContent(Translation.ru);
    } else {
      setContent(Translation.uz);
    }
    localStorage.setItem("lng", lang);
  }, [lang]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={<Main setLang={setLang} lang={lang} content={content} />}
        />
        <Route
          path="/select-d"
          element={<SelectD setLang={setLang} lang={lang} content={content} />}
        />
        <Route
          path="/next-p"
          element={<Nextp setLang={setLang} lang={lang} content={content} />}
        />
        <Route
          path="/view-p"
          element={<ViewP setLang={setLang} lang={lang} content={content} />}
        />
        <Route
          path="/finish"
          element={<Finish setLang={setLang} lang={lang} content={content} />}
        />
      </Routes>
    </div>
  );
}

export default App;
